/* .section-keybord{
    display: flex;
    height: 50vh;
    position: sticky;
    top: 164px;
} */
.keys-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: 0 auto;
}
.key-board-colors-wrapper{
   margin: 0 auto;
}

.key-step-wrapper{
    margin: 0 auto;
   text-align: center;
}
.color-select-step{
    width: 73%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #fff;
    font-size: medium;
    font-weight: 600;
    text-transform: uppercase;
}
.button-siwtch{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    height: auto;
}
.button-siwtch input{
    opacity: 0;
    width: 0;
    height: 0;
}
.button-acessibility{
    display: inline-block;
    width: 32px;
    height: 34px;
    background-image: url(../../images/olhos-desabilitado.png);
    background-repeat: no-repeat;
    opacity: 100%;
    -webkit-transition: .4s;
    transition: .4s;
    -moz-transition: .4s;
    -ms-transition: .4s;
    -o-transition: .4s;
}

input:checked + .button-acessibility{
    display: inline-block;
    width: 32px;
    height: 34px;
    opacity: 100%;
    background-image: url(../../images/olho-icone.png);
    background-repeat: no-repeat;
}

.keys-wrapper{
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items:flex-start;
    flex-wrap: nowrap;
    gap: 6px;
    width: 80%;
    padding: 9px;
    margin: 0 auto;

    animation: moveToLeft 0.3s ease-in both;
   -webkit-animation: moveToLeft 0.3s ease-in both;
}
.col-1,
.col-2,
.col-3{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-self: flex-start;
    gap: 6px;
    justify-content: center;
    align-items: center;
}
.recalc-btn{
    max-width: 80vw;
    width: 100%;
    min-width: 79px;
    height: 9vmax;
    background-color: #fff;
    color:#0496FF;
    font-size: 29px;
    font-weight: bold;
    border: 1px solid rgb(146, 206, 255);
    box-shadow: 0 3px 9px 1px #0044ff;
    border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
    animation: scaleUp 0.3s ease-in both;
    -webkit-animation: scaleUp 0.3s ease-in both;
}
.key-black,
.key-brown,
.key-red,
.key-orange,
.key-yellow,
.key-green,
.key-blue,
.key-violet,
.key-grey,
.key-white,
.key-gold,
.key-silver{
    max-width: 30vw;
    width: 26vw;
    min-width: 79px;
    height: 9vmax;
    color: #fff;
    border: 1px solid rgb(146, 206, 255);
    box-shadow: 0 3px 9px 1px #0044ff;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    
}

.black{ 
    background-color: black;  
}
.brown{
    background-color: #583412;
}
.red{
    background-color: rgb(255, 0, 0);
}
.orange{
    background-color: #FF6B00;
}
.yellow{
    background-color: rgb(255, 208, 0);
}
.green{
    background-color: #0DE309;
}
.blue{
    background-color: #0984E3;
}
.violet{
    background-color: #DC0EE0;
}
.grey{
    background-color: #8C9195;
}
.white{
    background-color: #ffff;
    color: #8C9195;
}
.gold{
    background-color: #cfa302;
    color: #ADFF00;
}
.silver{
    background-color: #A4B9C5;
    color: #E7EBDE;
}

/* ------------------- DESKTOP -----------------------------*/
@media screen and (min-width:1025px){

    .key-board-colors-wrapper{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        gap: 9px;
        padding:29px; 
    }

    .key-step-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    .color-select-step{
        width: 100%;
        font-size: small;
     }

    .key-black, .key-brown, .key-red, .key-orange, .key-yellow, .key-green, .key-blue, .key-violet, .key-grey, .key-white, .key-gold, .key-silver{
        max-width: 6vw;
        width: 4vw;
        min-width: 30px;
        height: 3vmax;
        border: none;
        box-shadow: 0 3px 9px 1px #00185a65;
        font-size: 0.6rem;
    }
    /* for btn hover efect */
    .key-black:hover, .key-brown:hover, .key-red:hover, .key-orange:hover, .key-yellow:hover, .key-green:hover, .key-blue:hover, .key-violet:hover, .key-grey:hover, .key-white:hover, .key-gold:hover, .key-silver:hover{
        box-shadow: 0px 0px 9px 3px #0044ff;
    }
    /* for btn active input */
    .key-black:active, .key-brown:active, .key-red:active, .key-orange:active, .key-yellow:active, .key-green:active, .key-blue:active, .key-violet:active, .key-grey:active, .key-white:active, .key-gold:active, .key-silver:active{
        box-shadow: 0 3px 19px 6px #a9a8d6;
        border: 2px solid rgb(146, 221, 255);
    }

    .recalc-btn{
        width: 14vw;
        height: 9vh;
        /* background-color: #f1f2f3;
        color: #000000; */
    }

    .button-siwtch{
        width: 25%;
        height: 3vh;
    }
    .button-acessibility{
        background-size: 80%;
        background-position: center;
    }
}
/* ------------------- FIM DESKTOP --------------------------*/

/* ------ ANIMATIONS ----- */

/* MOVE TO LEFT */
@-webkit-keyframes moveToLeft {
    0%{ 
        transform: translateX(-200px) ; opacity: 0%; -webkit-transform: translateX(-200px) ; opacity: 0%; -moz-transform: translateX(-200px) ; opacity: 0%;-ms-transform: translateX(-200px) ; opacity: 0%;-o-transform: translateX(-200px) ; opacity: 0%;} 
    100%{
        transform: translateX(0px); opacity: 100%;
        -webkit-transform: translateX(0px); opacity: 100%;
        -moz-transform: translateX(0px); opacity: 100%;
        -ms-transform: translateX(0px); opacity: 100%;
        -o-transform: translateX(0px); opacity: 100%;
    }
}

@-moz-keyframes moveToLeft {
    0%{ 
        transform: translateX(-200px) ; opacity: 0%; -webkit-transform: translateX(-200px) ; opacity: 0%; -moz-transform: translateX(-200px) ; opacity: 0%;-ms-transform: translateX(-200px) ; opacity: 0%;-o-transform: translateX(-200px) ; opacity: 0%;} 
    100%{
        transform: translateX(0px); opacity: 100%;
        -webkit-transform: translateX(0px); opacity: 100%;
        -moz-transform: translateX(0px); opacity: 100%;
        -ms-transform: translateX(0px); opacity: 100%;
        -o-transform: translateX(0px); opacity: 100%;
    }
}

@keyframes moveToLeft {
    0%{ 
        transform: translateX(-200px) ; opacity: 0%; -webkit-transform: translateX(-200px) ; opacity: 0%; -moz-transform: translateX(-200px) ; opacity: 0%;-ms-transform: translateX(-200px) ; opacity: 0%;-o-transform: translateX(-200px) ; opacity: 0%;} 
    100%{
        transform: translateX(0px); opacity: 100%;
        -webkit-transform: translateX(0px); opacity: 100%;
        -moz-transform: translateX(0px); opacity: 100%;
        -ms-transform: translateX(0px); opacity: 100%;
        -o-transform: translateX(0px); opacity: 100%;
    }
}

/* SCALE UP */

@-webkit-keyframes scaleUp{
    0%{
        transform: scale(0);
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
    }
    100%{
        transform: scale(1);
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
    }
}

@-moz-keyframes scaleUp{
    0%{
        transform: scale(0);
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
    }
    100%{
        transform: scale(1);
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
    }
}
@-o-keyframes scaleUp{
    0%{
        transform: scale(0);
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
    }
    100%{
        transform: scale(1);
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
    }
}

@-ms-keyframes scaleUp{
    0%{
        transform: scale(0);
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
    }
    100%{
        transform: scale(1);
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
    }
}

@keyframes scaleUp{
    0%{
        transform: scale(0);
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
    }
    100%{
        transform: scale(1);
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
    }
}