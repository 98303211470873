
html,
body{
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  background-image: linear-gradient(#076F9C,#51079C);
}

.App {
  width: 100%;
  /* text-align: center; */
}

.mobile-app{
  height: 100vh;
  background-image: url(../images/backgroundDetails-mobile.png);
  background-repeat: no-repeat;
  background-size: 93% 49%;
  background-position: 0vw 100%;
  background-attachment: fixed;
}

.desktop-app{
  padding: 0 3px;
  display: flex;
  flex-direction: row;
  background: 
  url("../images/backgroundDetails-desktop-left.webp")
  0vw 0vh/66% 80vh no-repeat fixed,
  url("../images/backgroundDetails-desktop-right.webp") top right/6% 49vh no-repeat;
}

.main-content-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: sticky;
  top: 0px;
}

.desktop-details{
  background-repeat: no-repeat;
}

.App-header {
  position: sticky;
  top: 0;
  min-height: 26vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/*------------------------ DESKTOP ------------------ */
@media screen and (min-width:1025px) {
  .main-content-wrapper{
    display: flex;
    justify-content: space-evenly;
    height: 70vh;
    gap: 30px;
  }
  
  .desktop-resistence-display{
    width: 20%;
    height: 100vh;
    padding-left: 3vw;
    /* background-color: #9c076a46; */
  }
  
  .App-header{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 3vh 33px;
  }
  .section-keybord{
    position: relative;
    top: 3%;
 
  }

  .App-header::after{
    content: 'SELECIONE AS CORES PARA OBTER O VALOR DA RESISTÊNCIA';
    opacity: 39%;
    padding: 12px 0;
    font-size: 9px;
    font-weight: 300;
    font-family: 'roboto', Arial, Helvetica, sans-serif;
 
  }

  .display-wrapper{
    width: 49%;
    height: 13vmax;
  }
}

/*------------------- FIM DESKTOP---------------- */

@keyframes App-logo-spin {
  from {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
}
  to {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
}
}
