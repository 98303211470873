
@font-face {
    font-family: 'Digital Numbers Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Digital Numbers Regular'), url('../../styles/fonts/DigitalNumbers-Regular.woff') format('woff');
}

.display-wrapper{
    width: 85%;
    height: 21vmax;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 3%;
    background-color: #3AD8E2;
    border: 6px solid #135fa5;
}

.display-row1{
    width: 100%;
    height: 19vmax;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 3%;
}
.display-row2{
    width: 100%;
    height: 5vmax;
    display: flex;
    background-color: #3AD8E2;
    justify-content: space-between;
    align-items: center;
    padding: 0 3%;
}

.display-row2 .tolerance-details{
    font-size: small;
    font-weight: 100;
}

.display-value{
    font-family: Digital Numbers Regular,Helvetica, Arial, sans-serif;
    font-size: 9vmax;
    font-stretch:wider;
    letter-spacing: -3px;
    text-align: left;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 19px rgb(0, 106, 206);
  
}

.display-result-details{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.result-details-type{
    font-size: 3vmax;
    line-height: 1.3rem;
    transition: 1.6s;
    -webkit-transition: 1.6s;
    -moz-transition: 1.6s;
    -ms-transition: 1.6s;
    -o-transition: 1.6s;
}
.result-details-name{
    transition: 1.6s;
    -webkit-transition: 1.6s;
    -moz-transition: 1.6s;
    -ms-transition: 1.6s;
    -o-transition: 1.6s;
}
.result-details-symbol{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13vmax;
    line-height: 0.8em;
}

.inactive{
    opacity: 0.3;
}

.resistence-D-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3px;
    margin-top: 1vh;
}
.resistence-D-spacer{
    background-color: #fff;
    min-width: 4vmax;
    height: 1.6vmin;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}

.inSelection{
    -webkit-animation: pulse 1s ease-in-out infinite;
    -moz-animation: pulse 1s ease-in-out infinite;
    -ms-animation: pulse 1s ease-in-out infinite;
    -o-animation: pulse 1s ease-in-out infinite;
    animation: pulse 1s ease-in-out infinite;
}
/* ------------------- DESKTOP --------------------------*/
@media screen and (min-width:1025px) {

    .display-row1{
        align-items: flex-end;
    }

    .display-value{
        font-size: 6vmax;
    }
    .result-details-symbol{
        font-size: 6vmax;
    }
    .resistence-D-wrapper-desk{
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10.3vh;
        margin: 0;
        background-image: url("../../images/resistenceDesktop.webp");
        background-size: 20vw 109vh;
        background-position: center;
        background-repeat: no-repeat;
        filter: contrast(1.3);
        -webkit-filter: contrast(1.3);
}
    .resistence-D-spacer{
       
        background-color: #fff;
        min-width: 8.1vmax;
        height: 5vmin;
        margin-left: 2px;
    }
    .resistence-D-spacer:first-child{
        min-width: 10vmax;
        /* margin-left: 3px; */
        border-radius: 3px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        -o-border-radius: 3px;
}
    .resistence-D-spacer:last-child{
        min-width: 10vmax;
        margin-left: 0;
        border-radius: 3px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        -o-border-radius: 3px;
}
    
}
/* ------------------- FIM DESKTOP --------------------------*/


/*------------- animations------------- */

 /*Botão Pulsa*/
@-webkit-keyframes pulse {
    0% {
      opacity: 0%;  
    }
    50% {
        opacity: 100%;
    }
    100% {
        opacity: 0%;
    }
  }
  @-moz-keyframes pulse {
    0% {
        opacity: 0%;
    }

    50% {
        opacity: 100%;
    }
    100% {
        opacity: 0%;
    }
  }
  @-ms-keyframes pulse {
    0% {
        opacity: 0%;  
    }
    50% {
        opacity: 100%;
    }
    100% {
        opacity: 0%;
    }
  }
  @-o-keyframes pulse {
    
    0% {opacity: 0%;}
    50% {opacity: 100%;}
    100% {opacity: 0%;} 
  }
  @keyframes pulse { 
    0% { opacity: 0%;}
    50% {opacity: 100%;}
    100% {opacity: 0%;}
  }
  /*Botão Pulsa*/