
.footer-wrapper{
    width: 100%;
    /* height: 13vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 0vh 0 0 0;
}
.copy-wrapper{
    width: 100%;
    height: 13vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 9px 0 0 0;
}
h1{
    text-align: center;
    text-indent: 110%;
    white-space:pre-line;
    overflow-x: hidden;
}
h2{
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
    font-family: Roboto,'Helvetica Neue', BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, 'Open Sans', sans-serif;
}
h3,h4{
    color: #fff;
    font-size: 0.6rem;
    font-weight: 300;
    font-family: Roboto,'Helvetica Neue', BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, 'Open Sans', sans-serif;
    margin: 2rem 0 0 0;
}
h4{ 
    display: flex;
    flex-direction: column;
    margin: 2rem 0;
    text-align: center;
}
h4::before{
    content: 'Todos direitos reservados a Abraão Morais';
    visibility: hidden;
    opacity: 0%;
}
.App-logo {
    width: 6vmax;
    height: 7vmax;
    margin:0 -3vw;
    pointer-events: none;
  }

  /* ------------------- DESKTOP --------------------------*/
@media screen and (min-width:1025px) {
    .footer-wrapper{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 93%;  
      height: 16vh;
      padding: 9vh 29px 0px;
      /* margin: 0; */
    }
    .copy-wrapper{
      width: 9%;
      padding: 0;
    }
    .copy-wrapper img{
        padding-top: 1.3rem;    
    }

    .footer-wrapper h3{
        writing-mode:vertical-rl;
        -webkit-writing-mode:vertical-rl;
        -ms-writing-mode:vertical-rl;
    }
    .footer-wrapper h3{
        position: absolute;
        right: 3px;
        bottom: 20px;
        letter-spacing: 3px;
    }
    .footer-wrapper h3::after{
        content: " Abraão Morais";
    }
    .footer-wrapper h2,
    .footer-wrapper h4{
      display: none;
    }
    .App-logo {
        width: 4vmax;
        height: 4vmax;
    }
}
/* ------------------- FIM DESKTOP --------------------------*/


  @media screen and (max-width: 521px) {
    .App-logo {
        margin:0 -8vw;
    }
  }

  